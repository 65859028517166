@import "~sass-includes/index";

.users-search {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.users-search__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.users-search__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  background-color: $gray-light;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;

  input[type="text"] {
    width: 250px;
    border: 1px solid;
    border-color: $gray;
    border-radius: 7px;
    margin: 0px 5px;
    padding: 10px 5px;
    line-height: normal;
  }

  #user-id {
    width: 350px;
  }
}
