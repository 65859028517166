@import "~sass-includes/index";

.sales-admin {
  @include centered($max-width);

  .sales-admin__nav {
    margin-top: 12px;
    button {
      display: inline-block;
      padding: 8px;
      &.selected {
        background-color: $blue-lighter;
      }
    }
  }

  .sales-admin__content {
    input[type="text"] {
      border: 1px solid;
      border-color: $gray;
      border-radius: 7px;
      padding: 10px 5px;
      line-height: normal;
    }

    button {
      margin: 5px;
      &.reset-button {
        background-color: $white;
        color: $true-black;
        border: 1px solid $gray-dark;
      }
    }

    input {
      height: 50px;
    }

    label {
      font-weight: bold;
    }

    // only apply margin to input labels
    form > label, > div > label {
      margin: 8px 0;
    }

    .result {
      font-size: $font-size-lg;
      div, span {
        padding: 10px;
        border-radius: $border-radius-xs;
        font-weight: bold;
      }
      .success {
        background-color: $green-light;
      }
      .warn {
        background-color: $gold-light;
      }
      .error {
      }
    }
  }

  .new-user {
    display: flex;
    flex-direction: row;

    input[type="text"] {
      width: 500px;
      min-width: 400px;
    }

    .new-user__form {
      display: flex;
      flex-direction: column;
      padding: 5px;
    }

    .result {
      padding: 5px;
    }
  }

  .enroll-user {
    input[type="text"] {
      width: 340px;
      min-width: 340px;
    }

    table {
      min-height: 122px;
    }

    tr {
      height: 61px;
    }

    p {
      margin: 0px;
    }

    .enroll-user__user {
      .users-search {
        margin-left: 0px;
      }
      th {
        width: 150px;
      }
      .users-table__name {
        width: 250px;
      }
      .users-table__email {
        width: 400px;
      }
    }

    .enroll-user__course {
      margin-top: 30px;

      .courses-table__id {
        width: 300px;
      }
      .courses-table__name {
        width: 300px;
      }
      .courses-table__number {
        width: 200px;
      }
      .courses-table__date {
        width: 150px;
      }
      .courses-table__instructor {
        width: 250px;
      }
    }

    .enroll-user__enroll {
      margin-top: 30px;
      min-height: 70px;

      font-weight: bold;
      font-size: $font-size-lg;

      .enroll-user__enroll-select-user {
        padding: 10px;
        border-radius: $border-radius-xs;
        background-color: $gold-light;
      }

      .enroll-user__enroll-prompt {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px;

        border-radius: $border-radius-xs;
        background-color: $blue-light;

        button {
          margin-left: 20px;
        }
      }
    }

    .users-container-table__item-row {
      &.is-selected {
        background: $purple-light;
      }
    }
  }
}
